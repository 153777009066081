import $ from 'jquery';
import 'slick-carousel';

export default () => {



  $(() => {

    // Top page hero slider
    $('.l-hero').slick({
      centerMode: true,
      centerPadding: '20%',
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 640, // 640px以下のサイズに適用
          settings: {
          slidesToShow: 1,
          centerPadding: '0%',
          },
        },
      ],
    });

    // PC用検索ボタン
    function checkWidth() {
      var windowWidth = $(window).width();
      if (windowWidth < 768) {
        $(".searchFormWindow").hide();
        $(".js-searchForm-toggle").removeClass("active");
      }
    }
    checkWidth();
    $(window).on("resize", function() {
      checkWidth();
    });
    $(".js-searchForm-toggle").on("click", function() {
      var windowWidth = $(window).width();
      if (windowWidth >= 768) {
        $(".searchFormWindow").slideToggle();
        $(this).toggleClass("active");
      }
    });


    // ハンバーガーメニュー
    $('.l-header__hamburgerbtn').on('click',function() {
      $(this).toggleClass('active');
      if ($(this).hasClass('active')) {
        $('.l-navi.pc_sp').addClass('active');
        $('.l-header__icons').addClass('active');
      } else {
        $('.l-navi.pc_sp').removeClass('active');
        $('.l-header__icons').removeClass('active');
      }
    });

    // サイドバー
    $(".has-subList.active").next(".sidebar__subList").show();

    $(".has-subList").on("click", function() {
      $(this).next().slideToggle();
      $(this).toggleClass("active");
    });

    $(".sidebar__trigger-inner .has-subList").on("click", function() {
      $(".sidebarMenu").fadeToggle();
      $(this).toggleClass("active");
    });

    $(".sidebarMenu").hide();
    $(".btn_menu_close").on("click", function() {
      $(".sidebarMenu").fadeToggle();
      $(this).toggleClass("active");
    });

    // Detail page image slider
    $('.slider-for').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: '.slider-nav'
    });
    $('.slider-nav').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      asNavFor: '.slider-for',
      focusOnSelect: true,
      arrows: true,
      variableWidth: true 
    });

    // calculate header height
    function calculateHeaderHeight() {
      var headerHeight = $('header').outerHeight();
      var menuHeight = $('header .w-full').outerHeight();
      $('.l-main').css('padding-top', headerHeight + 5);
      $('.l-navi.pc_sp').css('top', menuHeight);      
    }
  
    // Call the function initially
    calculateHeaderHeight();
  
    // Recalculate header height on window resize
    $(window).resize(function() {
      calculateHeaderHeight();
    });


  });

}